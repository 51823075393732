
import { Component, Prop, Vue } from "vue-property-decorator";
import Footer from "@/components/Home/Footer/Footer.vue";
import CallToAction from "@/components/Home/CallToAction/CallToAction.vue";

/**
 * Generic Page component
 */
@Component({
  name: "SharedPage",
  components: {
    Footer,
    CallToAction
  },
})
export default class Page extends Vue {
  /**
   * Title
   */
  @Prop()
  private title!: string;
  /**
   * Subtitle
   */
  @Prop()
  private subtitle!: string;
  /**
   * Image to appear behind the header
   */
  @Prop({ default: null })
  private img!: string | null;

  private get titleClass() {
    return this.$vuetify.breakpoint.xs ? "text-h3" : "text-h2";
  }
}
