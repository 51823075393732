
import { Component, Vue } from "vue-property-decorator";
import Teaser from "@/components/Shared/Teaser/Teaser.vue";
import BasePage from "@/components/Shared/Page/Page.vue";
import UploadedFile from "@/entities/UploadedFile";
import { features } from "@/entities/data";

/**
 * Features page component
 */
@Component({
  name: "FeaturesPage",
  components: {
    Teaser,
    BasePage,
  },
})
export default class Page extends Vue {
  private features = features;

  private finish(uploadedFile: UploadedFile) {
    /**
     * Emit the uploaded file
     *
     * @type {UploadedFile}
     */
    this.$emit("finish", uploadedFile);
  }
}
